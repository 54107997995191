import { template as template_afd84158ff03458ca3b76dd81599b360 } from "@ember/template-compiler";
const WelcomeHeader = template_afd84158ff03458ca3b76dd81599b360(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
