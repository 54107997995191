import { template as template_5f96a58c4fa94682a52bd6bd9128116e } from "@ember/template-compiler";
const SidebarSectionMessage = template_5f96a58c4fa94682a52bd6bd9128116e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
