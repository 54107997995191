import { template as template_cd8b3dffd1f4462c8204964085799259 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_cd8b3dffd1f4462c8204964085799259(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
